import { FC, useRef } from 'react';
import { InputField } from '@studenten/ui-components';

import { SearchContainer, StyledSearchButton } from '../SearchResultsSection.styles';

export type MobileSearchContainerProps = {
  onClickSearch?: (value: string) => void;
  initValue?: string;
};

export const MobileSearchContainer: FC<MobileSearchContainerProps> = ({
  onClickSearch,
  initValue = '',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = () => {
    if (typeof onClickSearch === 'function' && inputRef?.current) {
      onClickSearch(inputRef.current.value);
    }
  };

  return (
    <SearchContainer>
      <InputField
        name="search"
        placeholder="zoek op keyword"
        defaultValue={initValue}
        ref={inputRef}
      />
      <StyledSearchButton onClick={onClick}>Zoeken</StyledSearchButton>
    </SearchContainer>
  );
};
