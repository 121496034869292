import { ParsedUrlQuery } from 'querystring';

import {
  jobFunctionsNew,
  hours,
  education,
  popularCities,
  isVerified,
} from '@studenten/shared/constants/filters';
import { makeAutoObservable, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { useMemo } from 'react';
import { createFilterItems, getKeyValue, getSearchParams } from 'lib/filters';
import { vacanciesApi } from 'api_entities/vacancies';
import { CancelTokenSource, fetcher } from 'api_entities/fetch';
import { FacetedResponse } from 'api_entities/types';
import { Job } from 'api_entities/vacancies/types';
import { FilterItem } from 'lib/filters/types';

import { IJobsSearchStore } from './types';

enableStaticRendering(typeof window === 'undefined');

let source: CancelTokenSource;
let store: JobsSearchStore;
export class JobsSearchStore implements IJobsSearchStore {
  data: FacetedResponse<Job> = { data: [], totalCount: 0, totalFacets: {}, facets: {} };
  filters: IJobsSearchStore['filters'] = {
    isVerified: isVerified.map(({ label, value }) => ({ label, value: value.toLowerCase() })),
    type: hours.map(({ label, value }) => ({ label, value: value.toLowerCase() })),
    jobFunction: jobFunctionsNew.map(({ label, value }) => ({ label, value: value.toLowerCase() })),
    city: popularCities.map(({ label, value }) => ({ label, value: value.toLowerCase() })),
    education: education.map(({ label, value }) => ({ label, value: value.toLowerCase() })),
  };
  constructor() {
    makeAutoObservable(this);
  }
  async deleteAllSelectedFilters(query: ParsedUrlQuery) {
    await this.fetchData(query, true);
    await this.fetchFilters(query, null);
  }
  async deleteSelectedFilter(
    query: ParsedUrlQuery,
    key: keyof IJobsSearchStore['filters'],
    value: string
  ) {
    const targetItem: FilterItem | undefined = getKeyValue(this.filters)(key).find(
      (item: FilterItem) => item.value === value
    );
    if (targetItem?.checked) {
      targetItem.checked = false;
    }
    await this.fetchFilters(query, key);
  }
  toggleFilters = (data: Array<FilterItem>, filterKey: keyof IJobsSearchStore['filters']) => {
    runInAction(() => {
      this.filters = {
        ...this.filters,
        [filterKey]: (this.filters[filterKey] as Array<FilterItem>).map((item, idx) => ({
          ...item,
          checked: data[idx].checked,
        })),
      };
    });
  };
  async fetchFilters(query: ParsedUrlQuery, filterKey: keyof IJobsSearchStore['filters'] | null) {
    try {
      if (filterKey) {
        await this.fetchData(query, true);
        Object.entries(this.filters).map(async ([key]) => {
          if (query[key]?.length && key !== 'page') {
            runInAction(() => {
              this.filters[key] = this.filters[key].map((subItem) => {
                return {
                  ...subItem,
                  counter: this.data.facets?.[key]?.[subItem.value] || 0,
                };
              });
            });
          } else {
            runInAction(() => {
              this.filters[key] = this.filters[key].map((subItem) => {
                return {
                  ...subItem,
                  counter: this.data.totalFacets?.[key]?.[subItem.value] || 0,
                };
              });
            });
          }
        });
      } else {
        runInAction(() => {
          this.filters = {
            isVerified: createFilterItems(
              this.filters.isVerified,
              'isVerified',
              this.data.totalFacets,
              this.data.facets,
              query
            ),
            type: createFilterItems(
              this.filters.type,
              'type',
              this.data.totalFacets,
              this.data.facets,
              query
            ),
            jobFunction: createFilterItems(
              this.filters.jobFunction,
              'jobFunction',
              this.data.totalFacets,
              this.data.facets,
              query
            ),
            city: createFilterItems(
              this.filters.city,
              'city',
              this.data.totalFacets,
              this.data.facets,
              query
            ),
            education: createFilterItems(
              this.filters.education,
              'education',
              this.data.totalFacets,
              this.data.facets,
              query
            ),
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  hydrate(initialData: IJobsSearchStore['data'], initialFilters: IJobsSearchStore['filters']) {
    runInAction(() => {
      this.data = initialData;
      this.filters = initialFilters;
    });
  }
  async fetchData(query: ParsedUrlQuery, resetPage?: boolean) {
    const params = getSearchParams(query, resetPage);
    if (source) {
      source.cancel('Operation canceled by the user.');
    }
    source = fetcher.CancelToken.source();
    const data = await vacanciesApi.getJobs(params, source);
    runInAction(() => {
      this.data = data;
    });
  }
}

function initializeStore(
  initialData?: IJobsSearchStore['data'],
  initialFilters?: IJobsSearchStore['filters']
) {
  const _store = store ?? new JobsSearchStore();

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here

  if (initialData && initialFilters) {
    _store.hydrate(initialData, initialFilters);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}

export function useStore(
  initialData?: IJobsSearchStore['data'],
  initialFilters?: IJobsSearchStore['filters']
) {
  const store = useMemo(() => initializeStore(initialData, initialFilters), [
    initialData,
    initialFilters,
  ]);

  return store;
}
