import styled from 'styled-components';
import { Button } from '@studenten/ui-components';
import { device } from 'constants/device';

export const Container = styled.div`
  padding: 24px;
  background: ${({ theme }) => theme.system.white};
  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  box-sizing: border-box;
  border-radius: 8px;
  height: fit-content;

  @media ${device.laptopL} {
    margin-top: 55px;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(1.8 * env(safe-area-inset-bottom) + 120px);
  }
  @media ${device.tablet} {
    width: 272px;
  }
`;

export const MobileHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.system.lightGrey};
  & > button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin-right: 18px;
    svg {
      color: ${({ theme }) => theme.brand.darkBlue};
    }
  }
  & > div {
    flex: 1;
  }
`;

export const HeaderResultsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileResultsCounterWrapper = styled.div`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: bold;
  color: ${({ theme }) => theme.system.black};
`;

export const ClearAllFiltersButtonWrapper = styled.div`
  & > button {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.system.red};
  }
`;

export const MobileApplyButtonWrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 55px;
  padding: 8px 16px;
  padding-bottom: calc(1.8 * env(safe-area-inset-bottom));
  z-index: 2;
  background: ${({ theme }) => theme.system.white};
  border-top: 1px solid ${({ theme }) => theme.system.lightGrey};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  & > button {
    height: 38px;
    width: 100%;
  }
  @media ${device.laptopL} {
    display: block;
  }
`;

export const BlockHeader = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.1rem;

  color: ${({ theme }) => theme.system.black};
`;

export const StyledApplyButton = styled(Button)`
  border-radius: 9px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8rem;

  background: ${({ theme }) => theme.system.white};
  border: 1px solid ${({ theme }) => theme.brand.orange};
  color: ${({ theme }) => theme.brand.orange};
  transition: ease all 0.3ms;

  &[disabled],
  &[disabled]:hover {
    border-color: ${({ theme }) => theme.system.primaryNonActive};
    color: ${({ theme }) => theme.system.primaryNonActive};
    cursor: not-allowed;
  }

  &:hover {
    border-color: ${({ theme }) => theme.brand.darkOrange};
    color: ${({ theme }) => theme.brand.darkOrange};
    cursor: pointer;
  }
`;
