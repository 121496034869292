import { FC, memo, useContext, RefObject } from 'react';
import { ThemeContext } from 'styled-components';
import ReactPaginate from 'react-paginate';
import { useRouter } from 'next/router';
import { Button, Icons } from '@studenten/ui-components';

import { size } from 'constants/device';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';

import { Container } from './Pagination.styled';

export type PaginationProps = {
  shallow?: boolean;
  elementsToShow?: number;
  totalCount: number;
  searchResultsRef?: RefObject<HTMLDivElement>;
};

export const Pagination: FC<PaginationProps> = ({
  shallow = false,
  totalCount,
  elementsToShow = 5,
  searchResultsRef,
}) => {
  const isBrowser = typeof window !== 'undefined';
  const themeContext = useContext(ThemeContext);

  const router = useRouter();
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < size.tablet;

  const handleChangePage = async (page: number): Promise<void> => {
    if (isBrowser) {
      if (searchResultsRef?.current?.offsetTop) {
        window?.scrollTo(
          0,
          searchResultsRef.current.offsetTop - parseInt(themeContext.dimensions.headerHeight)
        );
      }

      const params = new URLSearchParams(window.location.search);
      params.set('page', String(page + 1));
      await router.push(`${window.location.pathname}/?${params.toString()}`, undefined, {
        shallow,
        scroll: false,
      });
    }
  };

  const getInitialPage = (): number => {
    if (isBrowser) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('page')) {
        return Number(params.get('page')) - 1;
      }
    }
    return 0;
  };

  if (isBrowser) {
    const hrefBuilder = (page: number): string => {
      return `${window.location.pathname}?page=${page}`;
    };

    return (
      <Container>
        <ReactPaginate
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={getInitialPage()}
          disableInitialCallback
          pageCount={Math.ceil(totalCount / elementsToShow)}
          pageRangeDisplayed={isMobile ? 1 : 3}
          marginPagesDisplayed={isMobile ? 1 : 3}
          previousLabel={
            <Button variant="base">
              <Icons.LeftArrow />
            </Button>
          }
          nextLabel={
            <Button variant="base">
              <Icons.RightArrow />
            </Button>
          }
          activeLinkClassName="activeLink"
          hrefBuilder={hrefBuilder}
        />
      </Container>
    );
  }

  return null;
};

export default memo(Pagination);
