import styled from 'styled-components';
import { Button } from '@studenten/ui-components';
import { device } from 'constants/device';

export const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 24px;
  padding-bottom: 64px;
  min-height: calc(100vh - 224px);

  @media ${device.laptopL} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    padding-top: 16px;
    min-height: calc(100vh - 177px);
  }
`;

export const DesktopSearchWrapper = styled.div`
  position: relative;
  display: block;
  flex: 0 0 376px;
  margin-top: -84px;
  margin-right: 40px;

  @media ${device.laptopL} {
    margin: 0;
    flex: 0 0 auto;
  }
`;

export const MobileSearchWrapper = styled.div`
  display: none;
  position: relative;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.system.white};

  @media ${device.laptopL} {
    display: block;
  }
`;

export const IconWrapper = styled.span`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const FilterBlockHeader = styled.span`
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.3rem;
  padding-right: 24px;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.system.black};
`;

export const ResultsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 16px;
  }
`;

export const H1 = styled.h1`
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 32px;

  color: ${({ theme }) => theme.system.black};
`;

export const P = styled.p`
  font-size: 1.6rem;
  line-height: 1.35;

  color: ${({ theme }) => theme.system.darkGreyActive};
`;

export const StyledLink = styled.div`
  color: ${({ theme }) => theme.brand.orange};
  text-decoration: underline;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding: 20px 0;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledSearchButton = styled(Button)`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  border-radius: 9px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8rem;

  background: ${({ theme }) => theme.system.white};
  border: 1px solid ${({ theme }) => theme.brand.orange};
  color: ${({ theme }) => theme.brand.orange};
  transition: ease all 0.3ms;

  &[disabled],
  &[disabled]:hover {
    border-color: ${({ theme }) => theme.system.primaryNonActive};
    color: ${({ theme }) => theme.system.primaryNonActive};
    cursor: not-allowed;
  }

  &:hover {
    border-color: ${({ theme }) => theme.brand.darkOrange};
    color: ${({ theme }) => theme.brand.darkOrange};
    cursor: pointer;
  }
`;
