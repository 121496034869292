import { FilterPageConfigItem } from 'lib/filters/types';

export const jobsFilters: Array<FilterPageConfigItem> = [
  {
    key: 'search',
    title: 'Search',
    type: 'search',
  },
  {
    key: 'isVerified',
    title: 'Verified',
    type: 'filter',
  },
  {
    key: 'type',
    title: 'Dienstverbanden',
    minFiltersToShow: 5,
    type: 'filter',
  },
  {
    key: 'jobFunction',
    title: 'Beroepsgroepen',
    minFiltersToShow: 5,
    type: 'filter',
  },
  {
    key: 'city',
    title: 'Plaatsen',
    minFiltersToShow: 5,
    type: 'filter',
  },
  {
    key: 'education',
    title: 'Opleidingsniveaus',
    type: 'filter',
  },
];
