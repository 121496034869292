import { MarkdownToJSX } from 'markdown-to-jsx';
import { P, H1 } from '../SearchResultsSection.styles';

export const mainTextContentOptions: MarkdownToJSX.Options = {
  forceBlock: false,
  forceWrapper: false,
  overrides: {
    p: {
      component: P,
    },
    h1: {
      component: H1,
    },
  },
};
