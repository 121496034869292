import { useRef } from 'react';
import { useRouter } from 'next/router';
import { RemoveScroll } from 'react-remove-scroll';
import { observer } from 'mobx-react';

import { size } from 'constants/device';
import { Drawer, Icons, Overlay, Button } from '@studenten/ui-components';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';
import { useOutsideClick } from 'features/layout/hooks/useOutsideClick';

import FilterBox from './components/FilterBox';
import {
  Container,
  MobileHeader,
  BlockHeader,
  MobileApplyButtonWrapper,
  MobileResultsCounterWrapper,
  HeaderResultsContainer,
  StyledApplyButton,
} from './SearchFilters.styled';

import type { Props } from './types';

export const SearchFilters = observer(
  ({ filters, store, totalCount, isOpen, setOpen, transformQuery }: Props) => {
    const router = useRouter();
    const drawerRef = useRef<HTMLDivElement | null>(null);
    const { width: windowWidth } = useWindowSize();
    const closeDrawer = () => setOpen(false);
    useOutsideClick(drawerRef, closeDrawer);
    const isDesktop = windowWidth >= size.laptopL;

    const renderFiltersCaterory = () => {
      return filters.map((filter, i) => {
        let FilterTagName;

        switch (filter.type) {
          case 'filter': {
            FilterTagName = FilterBox.FilterGroup;
            break;
          }

          case 'range': {
            FilterTagName = FilterBox.RatingGroup;
            break;
          }

          default: {
            return null;
          }
        }

        return (
          <FilterTagName
            key={filter.title}
            options={store.filters[filter.key]}
            title={filter.title}
            collapseable={i > 2}
            minFiltersToShow={filter.minFiltersToShow}
            filterPlaceholder={filter.filterPlaceholder}
            ignoreField={filter.ignoreField}
            onChange={async (data) => {
              store.toggleFilters(data, filter.key);
              const query = {
                ...router.query,
                page: '1',
                [filter.key]: data
                  .filter((element) => element.checked)
                  .map((element) => element.value),
              };

              await router.push(
                {
                  pathname: router.pathname,
                  query,
                },
                undefined,
                { shallow: true }
              );
              if (typeof transformQuery === 'function') {
                transformQuery(query);
              }
              if ('requestIdleCallback' in window) {
                window.requestIdleCallback(async () => {
                  await store.fetchFilters(query, filter.key);
                });
              } else {
                await store.fetchFilters(query, filter.key);
              }
            }}
          />
        );
      });
    };

    if (isDesktop) {
      const initialSearchValue = Array.isArray(router.query?.query)
        ? router.query.query[0]
        : router.query?.query || '';

      return (
        <Container>
          <BlockHeader>Filters</BlockHeader>
          <FilterBox.SearchGroup
            title="search"
            initialValue={initialSearchValue}
            onChange={async (searchValue: string) => {
              const query: Record<string, any> = {
                page: '1',
                query: searchValue,
              };

              if (!searchValue) {
                delete query.query;
              }

              if (typeof transformQuery === 'function') {
                transformQuery(query);
              }

              await router.push({
                pathname: router.pathname,
                query,
              });
            }}
          />
          {renderFiltersCaterory()}
        </Container>
      );
    }

    return (
      <>
        <Overlay isOpen={isOpen} />
        <RemoveScroll enabled={isOpen}>
          <Drawer isOpen={isOpen} ref={drawerRef}>
            <MobileHeader>
              <Button variant="base" onClick={closeDrawer}>
                <Icons.Cross size="large" />
              </Button>
              <HeaderResultsContainer>
                <MobileResultsCounterWrapper>
                  <span>{totalCount} resultaten</span>
                </MobileResultsCounterWrapper>
              </HeaderResultsContainer>
            </MobileHeader>
            <Container>{renderFiltersCaterory()}</Container>
            <MobileApplyButtonWrapper>
              <StyledApplyButton onClick={closeDrawer}>Apply Filters</StyledApplyButton>
            </MobileApplyButtonWrapper>
          </Drawer>
        </RemoveScroll>
      </>
    );
  }
);
