import { useState, ReactNode, useRef } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
import { Icons } from '@studenten/ui-components';

import { JobsSearchStore } from 'features/search/models/jobsSearch';
import { ContentWrapper } from 'src/common';
import { SearchVacancyCard } from 'src/components/Cards';
import SearchFilters from 'src/components/SearchFilters';
import { Pagination } from 'src/components/Pagination';
import { RichText } from 'src/components/RichText';
import { APP_ROUTES } from 'constants/routes';
import { useUpToTablet } from '../../../hooks/useMediaQuery';

import { MobileSearchContainer } from './components/MobileSearchContainer';
import { mainTextContentOptions } from './components/mainTextContentOptions';
import { FilterPageConfigItem } from 'lib/filters/types';

import {
  Layout,
  DesktopSearchWrapper,
  ResultsWrapper,
  MobileSearchWrapper,
  FilterBlockHeader,
  IconWrapper,
} from './SearchResultsSection.styles';

export type SearchResultsSectionProps = {
  store: JobsSearchStore;
  filters: Array<FilterPageConfigItem>;
  renderBanner?: () => ReactNode;
  mainTextContent?: string;
  transformQuery?: (query?: Record<string, string | string[] | undefined>) => void;
  breadcrumbs?: ReactNode;
};

const SearchResultsSection = observer(
  ({
    filters,
    mainTextContent,
    renderBanner,
    transformQuery,
    store,
    breadcrumbs,
  }: SearchResultsSectionProps) => {
    const searchResultsContainerRef = useRef<HTMLDivElement>(null);
    const isMobile = useUpToTablet();
    const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
    const router = useRouter();

    const { data: jobsData } = store;

    const showPagination = !!jobsData?.data?.length || !!jobsData.totalCount;

    const handleApplyClick = (slug: string) => {
      void router.push(`${APP_ROUTES.ALL_VACANCIES}/${slug}/apply`);
    };

    return (
      <section>
        {typeof renderBanner === 'function' ? renderBanner() : null}
        <ContentWrapper>
          <Layout>
            <DesktopSearchWrapper>
              <SearchFilters
                filters={filters}
                store={store}
                totalCount={jobsData.totalCount}
                isOpen={isMobileFiltersOpen}
                setOpen={setMobileFiltersOpen}
                transformQuery={transformQuery}
              />
            </DesktopSearchWrapper>
            <MobileSearchWrapper>
              <FilterBlockHeader>Filters</FilterBlockHeader>
              <IconWrapper onClick={() => setMobileFiltersOpen(true)}>
                <Icons.FilterBurger />
              </IconWrapper>
              {/* eslint-disable-next-line no-console */}
              <MobileSearchContainer
                initValue={
                  Array.isArray(router.query?.query)
                    ? router.query.query[0]
                    : router.query?.query || ''
                }
                onClickSearch={async (searchValue: string) => {
                  const query: Record<string, any> = {
                    page: '1',
                    query: searchValue,
                  };

                  if (!searchValue) {
                    delete query.query;
                  }

                  if (typeof transformQuery === 'function') {
                    transformQuery(query);
                  }

                  await router.push({
                    pathname: router.pathname,
                    query,
                  });
                }}
              />
            </MobileSearchWrapper>
            <ResultsWrapper ref={searchResultsContainerRef}>
              {breadcrumbs}

              {mainTextContent ? (
                <RichText options={mainTextContentOptions}>{mainTextContent}</RichText>
              ) : null}

              {jobsData?.data?.map((job: any) => (
                <SearchVacancyCard
                  key={job.slug}
                  isMobile={isMobile}
                  onApplyClick={handleApplyClick}
                  {...job}
                />
              ))}

              {showPagination ? (
                <Pagination
                  totalCount={jobsData?.totalCount || 0}
                  searchResultsRef={searchResultsContainerRef}
                />
              ) : null}
            </ResultsWrapper>
          </Layout>
        </ContentWrapper>
      </section>
    );
  }
);

export default SearchResultsSection;
