import { FC, useRef } from 'react';
import { InputField } from '@studenten/ui-components';

import { Props } from './types';

import { Container, StyledSearchButton } from './FilterBox.styled';

export type SearchGroupProps = {
  onChange: (value: string) => void;
  initialValue?: string;
} & Pick<Props, 'title'>;

export const SearchGroup: FC<SearchGroupProps> = ({ onChange, title, initialValue = '' }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = () => {
    if (typeof onChange === 'function' && inputRef?.current) {
      onChange(inputRef.current.value);
    }
  };

  return (
    <Container id={`filter-${title}`}>
      <InputField
        name="search"
        placeholder="zoek op keyword"
        defaultValue={initialValue}
        ref={inputRef}
      />
      <StyledSearchButton onClick={onClick}>
        zoeken
      </StyledSearchButton>
    </Container>
  );
};
