import { JobsSearchStore, useStore as useJobsStore } from 'features/search/models/jobsSearch';
import { jobsFilters } from 'features/search/templates/JobsSearch/filters';
import { IJobsSearchStore } from 'features/search/models/types';
import { FilterPageConfigItem } from '../../../lib/filters/types';
import { deleteFilterFromConfig } from '../../../lib/filters';

export type PageConfig = {
  title?: string;
  store: typeof JobsSearchStore;
  useStore: (
    initialData?: IJobsSearchStore['data'],
    initialFilters?: IJobsSearchStore['filters']
  ) => JobsSearchStore;
  filters: Array<FilterPageConfigItem>;
};

type Pages = 'all-vacancies' | 'city' | 'job-function' | 'job-function-in-city';

export const pageConfig: Record<Pages, PageConfig> = {
  ['all-vacancies']: {
    title: 'Banen',
    store: JobsSearchStore,
    useStore: useJobsStore,
    filters: jobsFilters,
  },
  ['city']: {
    title: 'Banen',
    store: JobsSearchStore,
    useStore: useJobsStore,
    filters: deleteFilterFromConfig(jobsFilters, ['city']),
  },
  ['job-function']: {
    title: 'Banen',
    store: JobsSearchStore,
    useStore: useJobsStore,
    filters: deleteFilterFromConfig(jobsFilters, ['jobFunction']),
  },
  ['job-function-in-city']: {
    title: 'Banen',
    store: JobsSearchStore,
    useStore: useJobsStore,
    filters: deleteFilterFromConfig(jobsFilters, ['jobFunction', 'city']),
  },
};

export type PageConfigKey = keyof typeof pageConfig;
